import React from 'react'
import '../../tailwind.css';

const Cards = ({ title, imgSrc, link }) => {
    return (
        <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="relative rounded-lg shadow-secondary-1 dark:bg-surface-dWark w-[400px] h-[250px] overflow-hidden group bg-black"
        >
            <img
                className="absolute inset-0 w-full h-full object-cover group-hover:opacity-60 transition-opacity duration-300"
                src={imgSrc}
                alt={title}
            />
            <div
                className="absolute inset-0 bg-gradient-to-t from-black via-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            ></div>
            <div
                className="absolute bottom-0 w-full p-6 text-white opacity-0 group-hover:!opacity-100 transition-opacity duration-300"
            >
                <h5 className="fw-light leading-tight">{title}</h5>
            </div>
        </a>
    );
}

export default Cards