import React from 'react'
import Formulario from './Formulario'

const Contacto = () => {
  return (
    <section id='contacto' className='pt-3 pb-3 '>
      <div className="container pt-5 pb-5">
        <div className="row justify-content-between flex-md-row align-items-center align-items-md-start flex-column">

          <div className="col-10 col-md-5">
            <h1 className="text-white mb-3" >Contactanos</h1>
            <p className="text-white mb-3" >Para cualquier consulta, solicitud de información o si deseas conversar sobre cómo nuestras soluciones pueden ayudar a tu negocio, no dudes en contactarnos. Completa el formulario abajo o envíanos un correo directamente a tuemail@tudominio.com. Prometemos responder a la brevedad posible.s</p>
            <div className="redes">
              <a href="https://www.instagram.com/dscode.py/">
                <i class='bx bxl-instagram me-3'></i>
              </a>
              <a href="mailto:contacto@dscode.com.py">
                <i class='bx bxl-gmail  me-3  '></i>
              </a>
              <a href="https://wa.link/e3tgkr">
                <i class='bx bxl-whatsapp' ></i>
              </a>
            </div>
          </div>
          <div className="col-10 col-md-6">
            <Formulario />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contacto