import img1 from '../../Assets/port1.jpg';
import img2 from '../../Assets/port2.jpg';
import img3 from '../../Assets/port3.png';
import img4 from '../../Assets/port4.jpg';
import img5 from '../../Assets/port5.jpg';
import img6 from '../../Assets/port6.jpg';

const data = [
    {
        title: "New Design",
        imgSrc: img1,
        link: "https://newdesignarq.com.py/",
    },
    {
        title: "Bio Expansion",
        imgSrc: img2,
        link: "https://bioexpansion.com.py/",
    },
    {
        title: "Giganet",
        imgSrc: img3,
        link: "https://giganet.com.py/",
    },
    {
        title: "Buybox To Champions",
        imgSrc: img4,
        link: "https://buyboxtochampions.com/",
    },
    {
        title: "Registro Nacional Gaming",
        imgSrc: img5,
        link: "https://www.rng.com.py/",
    },
    {
        title: "Tienda",
        imgSrc: img6,
        link: "https://sacjo.github.io/elchora/",
    },
];

export default data;
