import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from "../Assets/LogoBlanco@3x.png";
import { NavHashLink } from 'react-router-hash-link';

const NavHeader = () => {
    return (
        <Navbar expand="lg" >
            <Container className='mt-1 mb-1' >
                <Navbar.Brand href="#home" className='text-white'><img className="nav-logo" src={Logo} alt="logo" /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" className='bg-light' />
                <Navbar.Collapse id="basic-navbar-nav" className='justify-content-between' >
                    <Nav className="ms-auto align-items-center">
                        <NavHashLink smooth to="/#home" className="text-white px-3 py-2 ">Inicio</NavHashLink>
                        <NavHashLink smooth to="/#nosotros" className="text-white px-3 py-2 ">Nosotros</NavHashLink>
                        <NavHashLink smooth to="/#servicios" className="text-white px-3 py-2 ">Servicios</NavHashLink>
                        <NavHashLink smooth to="/portafolio" className="text-white px-3 py-2 ">Portafolio</NavHashLink>
                        <NavHashLink smooth to="/#contacto" className="text-white px-3 py-2 ">Contacto</NavHashLink>

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavHeader
