import React from 'react'
import '../tailwind.css';
import NavHeader from '../Components/NavHeader'
import Footer from '../Components/Footer'
import MatrixAnimation from '../Components/Effects/MatrixAnimation'
import Cards from '../Components/Portafolio/Cards'
import data from '../Components/json/data'


const Portafolio = () => {
  return (
    <>
      <NavHeader />
      <section id='portafolio' className='min-h-screen flex text-center port-titulo'>
        <div className='container mt-5'>
          <div className='text-white'>
            <h1 className='text-5xl fw-light mt-5'>Portafolio</h1>
          </div>

          <div className='container mx-auto px-5 pb-16 pt-3'>
            <div className="flex flex-col lg:flex-row justify-center items-center space-y-4 lg:space-y-0 lg:space-x-4">
              {data.slice(0, 3).map((card, index) => (
                <Cards
                  key={index}
                  title={card.title}
                  imgSrc={card.imgSrc}
                  link={card.link}
                />
              ))}
            </div>
            <div className="flex flex-col lg:flex-row justify-center items-center space-y-4 lg:space-y-0 lg:space-x-4 mt-5">
              {data.slice(3, 6).map((card, index) => (
                <Cards
                  key={index}
                  title={card.title}
                  imgSrc={card.imgSrc}
                  link={card.link}
                />
              ))}
            </div>
            <div className="flex flex-col lg:flex-row justify-center items-center space-y-4 lg:space-y-0 lg:space-x-4 mt-5">
              {data.slice(6, 9).map((card, index) => (
                <Cards
                  key={index}
                  title={card.title}
                  imgSrc={card.imgSrc}
                  link={card.link}
                />
              ))}
            </div>
            <div className="flex flex-col lg:flex-row justify-center items-center space-y-4 lg:space-y-0 lg:space-x-4 mt-5">
              {data.slice(9, 12).map((card, index) => (
                <Cards
                  key={index}
                  title={card.title}
                  imgSrc={card.imgSrc}
                  link={card.link}
                />
              ))}
            </div>
          </div>
        </div>
        <MatrixAnimation />
      </section>
      <Footer />
    </>
  )
}

export default Portafolio